import React from 'react'
import { graphql, PageProps } from 'gatsby'
import { PageContextProps, PageInfoProps, PublicationProps } from '../../types/pages'
import { SectionBase, SectionLogoList, SectionPush } from '../../types/sections'
import { ITutorialCard } from '../../types/resources'
import { Layout, Seo } from '../../components/commons'
import { Box } from '../../atoms'
import { Grid, Row, Col } from 'react-styled-flexboxgrid'
import { SECTION_MARGIN } from '../../utils/constants'
import Section from '../../components/sections'
import { ResourcesFilters, ResourcesHeroWithSearch, ResourcesResult } from '../../components/resources/commons'
import { ResourceContextProvider } from '../../contexts/ResourceContext'

interface TutorialsQuery {
  landing: {
    meta: PublicationProps
    pageInfo: PageInfoProps
    preHeadline: string
    headline: string
    subHeadline: string
    searchPlaceholder: string
    filterTagLabel: string
    filterAllLabel: string
    filterToolLabel: string
    featuredLabel: string
    featuredTutorials: Array<ITutorialCard>
    sections: Array<SectionPush | SectionLogoList>
  }
  tutorials: {
    nodes: Array<ITutorialCard>
  }
  tools: {
    nodes: Array<{
      id: string
      name: string
    }>
  }
  tags: {
    nodes: Array<{
      id: string
      name: string
    }>
  }
}

const Tutorials: React.FC<PageProps<TutorialsQuery, PageContextProps>> = ({ data, pageContext }) => {
  const { landing, tutorials, tags, tools } = data

  return (
    <Layout
      pageId={'id'}
      locale={pageContext.locale}
      allSlugLocales={pageContext.slugLocales}
      withoutPrefooter={landing.pageInfo.hidePrefooter}
    >
      <Seo
        slug={landing.pageInfo.slug}
        canonicalUrl={landing.pageInfo.canonicalUrl}
        locale={pageContext.locale}
        allSlug={pageContext.slugLocales}
        publication={landing.meta}
        content={landing.pageInfo.seo}
      />

      <ResourceContextProvider items={tutorials.nodes}>
        <ResourcesHeroWithSearch
          preHeadline={landing.preHeadline}
          headline={landing.headline}
          subHeadline={landing.subHeadline}
          searchPlaceholder={landing.searchPlaceholder}
        />

        <Box my={SECTION_MARGIN}>
          <Grid>
            <Row>
              <Col xs={12} md={3}>
                <Box position={'sticky'} top={'calc(70px + var(--banner-height))'}>
                  <ResourcesFilters
                    filters={[
                      {
                        id: 'tag',
                        label: landing.filterTagLabel,
                        options: [
                          { label: landing.filterAllLabel, value: 'all' },
                          ...tags.nodes.map((t) => ({ label: t.name, value: t.id })),
                        ],
                      },
                      {
                        id: 'tool',
                        label: landing.filterToolLabel,
                        options: [
                          { label: landing.filterAllLabel, value: 'all' },
                          ...tools.nodes.map((t) => ({ label: t.name, value: t.id })),
                        ],
                      },
                    ]}
                  />
                </Box>
              </Col>
              <Col xs={12} md={9}>
                <ResourcesResult
                  itemsPerRow={3}
                  featuredLabel={landing.featuredLabel}
                  featured={landing.featuredTutorials}
                />
              </Col>
            </Row>
          </Grid>
        </Box>

        {landing.sections.map((s: SectionBase) => s?.model && <Section key={s.id} {...s} />)}
      </ResourceContextProvider>
    </Layout>
  )
}

export const query = graphql`
  query TutorialsQuery($locale: String!, $tutorialsTags: [String!]!, $tutorialsTools: [String!]!) {
    landing: datoCmsResourcesGlobal(locale: $locale) {
      meta {
        firstPublishedAt
        publishedAt
        updatedAt
      }

      pageInfo: tutorialsPageInfo {
        ...pageInfoFragment
      }
      preHeadline: tutorialsPreHeadline
      headline: tutorialsHeadline
      subHeadline: tutorialsSubHeadline
      searchPlaceholder: tutorialsSearchPlaceholder
      filterAllLabel
      filterTagLabel: tutorialsFilterTagLabel
      filterToolLabel: tutorialsFilterToolLabel
      featuredLabel
      featuredTutorials {
        ...tutorialCardFragment
      }
      sections: tutorialsSections {
        ...sectionPushFragment
        ...sectionLogoListFragment
      }
    }
    tags: allDatoCmsResourceTag(
      locale: $locale
      filter: { id: { in: $tutorialsTags } }
      sort: { fields: name, order: ASC }
    ) {
      nodes {
        id
        name
      }
    }
    tools: allDatoCmsIntegration(
      locale: $locale
      filter: { id: { in: $tutorialsTools } }
      sort: { fields: name, order: ASC }
    ) {
      nodes {
        id
        name
      }
    }
    tutorials: allDatoCmsTutorial(locale: $locale) {
      nodes {
        ...tutorialCardFragment
      }
    }
  }
`

export default Tutorials
